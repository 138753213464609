import axios from 'axios'
// import dotenv from 'dotenv'
import jwtDefaultConfig from './jwtDefaultConfig'

// dotenv.config()

export default class JwtService {
  jwtConfig = { ...jwtDefaultConfig }

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.getToken()
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error
        if (response && response.status === 401) {
          sessionStorage.clear()
          // localStorage.removeItem("accessToken")
          // window.location.href = "/login"
        }
        return Promise.reject(error)
      }
    )
  }

  getToken() {
    return sessionStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getUserData() {
    return JSON.parse(sessionStorage.getItem('userData'))
  }
  setUserData({ role, decoded }) {
    const superUserAbility = {
      action: 'manage',
      subject: 'all'
    }
    if (role) {
      const useData = { ...decoded, ability: [superUserAbility] }
      sessionStorage.setItem('userData', JSON.stringify(useData))
    }
  }
  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }
  setToken(value) {
    sessionStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(data) {
    return axios.post(this.jwtConfig.loginEndpoint, data)
  }
  forgotPassword(data) {
    const endpoint = `${this.jwtConfig.forgotPasswordEndpoint}`
    return axios.post(endpoint, data)
  }
  ResetPassword(data) {
    const endpoint = `${this.jwtConfig.ResetPasswordEndpoint}`
    return axios.post(endpoint, data)
  }
  /** Documents */
  getAllClientDocuments() {
    const endpoint = `${this.jwtConfig.getAllClientDocumentsEndPoint}?ordering=-created_at`
    return axios.get(endpoint)
  }
  getClientDocuments(id) {
    const endpoint = `${this.jwtConfig.getAllClientDocumentsEndPoint}/${id}`
    return axios.get(endpoint)
  }
  registerClientDocuments(data) {
    const endpoint = `${this.jwtConfig.getAllClientDocumentsEndPoint}`
    return axios.post(endpoint, data)
  }
  deleteClientDocuments(id) {
    const endpoint = `${this.jwtConfig.getAllClientDocumentsEndPoint}/${id}`
    return axios.delete(endpoint)
  }
  updateClientDocuments(data, id) {
    const endpoint = `${this.jwtConfig.getAllClientDocumentsEndPoint}/${id}`
    return axios.patch(endpoint, data)
  }

  //**  My Provider */
  getMyProvider() {
    const endpoint = `${this.jwtConfig.getMyProviderEndPoint}`
    return axios.get(endpoint)
  }

  //**  Billing */
  getAllBilling(offset, limit) {
    const endpoint = `${this.jwtConfig.getAllBillingEndPoint}?offset=${offset}&limit=${limit}&ordering=-created_at`
    return axios.get(endpoint)
  }

  //**  My Profile */
  getMyProfile() {
    const endpoint = `${this.jwtConfig.getMyProfileEndPoint}`
    return axios.get(endpoint)
  }
  updateMyProfile(id, data) {
    const endpoint = `${this.jwtConfig.updateMyProfileEndPoint}${id}`
    return axios.patch(endpoint, data)
  }

  //**  Messages  */
  sendMessage(data) {
    const endpoint = `${this.jwtConfig.sendMessageEndpoint}`
    return axios.post(endpoint, data)
  }
  receiveMessage(offset, limit) {
    const endpoint = `${this.jwtConfig.sendMessageEndpoint}?offset=${offset}&limit=${limit}&ordering=-created_at`
    return axios.get(endpoint)
  }

  //** Appointments */
  getAllAppointments(offset, limit) {
    const endpoint = `${this.jwtConfig.getAllAppointmentsEndpoint}?offset=${offset}&limit=${limit}`
    return axios.get(endpoint)
  }
  getPrevAndNextAppointments() {
    const endpoint = `${this.jwtConfig.getPrevAndNextAppointmentsEndpoint}`
    return axios.get(endpoint)
  }
  checkInAppointmentsAction(id, data) {
    const endpoint = `${this.jwtConfig.checkInAppointmentsActionEndpoint}/${id}`
    return axios.patch(endpoint, data)
  }

  //**  Notifications */
  getAllNotifications(offset, limit, search) {
    let endpoint = `${this.jwtConfig.getAllNotificationsEndPoint}?offset=${offset}&limit=${limit}&ordering=-created_at`
    if (search) {
      endpoint += `&search=${search}`
    }
    return axios.get(endpoint)
  }
  markAllNotification() {
    const endpoint = `${this.jwtConfig.markAllNotificationEndPoint}`
    return axios.get(endpoint)
  }

  //** Stripe */
  getConnectedAccountId() {
    const endpoint = `${this.jwtConfig.getConnectedAccountEndPoint}`
    return axios.get(endpoint)
  }
  getAllStripeCard() {
    const endpoint = `${this.jwtConfig.getAllStripeCardEndPoint}`
    return axios.get(endpoint)
  }
  registerStripeCard(data) {
    const endpoint = `${this.jwtConfig.registerStripeCardEndPoint}`
    return axios.post(endpoint, data)
  }
  registerMoreStripeCard(data) {
    const endpoint = `${this.jwtConfig.registerMoreStripeCardEndPoint}`
    return axios.post(endpoint, data)
  }
  updateStripeCard(data) {
    const endpoint = `${this.jwtConfig.updateStripeCardEndPoint}`
    return axios.post(endpoint, data)
  }
  deleteStripeCard(id) {
    const endpoint = `${this.jwtConfig.registerMoreStripeCardEndPoint}/${id}`
    return axios.delete(endpoint)
  }
  payClientInvoiceStripeCard(id, data) {
    const endpoint = `${this.jwtConfig.payClientInvoiceStripeCardEndPoint}${id}/charge-by-stripe`
    return axios.post(endpoint, data)
  }
}
